<template>

    <b-card>

        <b-row>
            <b-col  cols="10" sm="10" md="10" xl="10" lg="10">

                    <h4 class="font-weight-bolder" style="text-align: left !important;    margin: 0;">
                        <feather-icon
                                    icon="ListIcon"
                                    size="21"
                                    class="color_icon"
                                    style="margin-right: 10px;"
                                    />Historial de solicitudes de adelantos de sueldo
                            </h4>

         
                    </b-col>

                    <b-col  cols="2" sm="2" md="2" xl="2" lg="2" style="    text-align: right;">
                    <b-skeleton v-if="loading" width="100%"  height="20px"></b-skeleton>
                    <p v-else style="cursor:pointer;    margin: 0;" @click="refresh()" > 
                                                                <feather-icon
                                                                icon="RefreshCcwIcon"
                                                                size="16"
                                                                style="margin-right:5px"
                                                                />  </p>
    
                    
                    </b-col>
        </b-row>

        <b-row v-if="loading">
          <b-col  cols="4" sm="4" md="4" xl="4" lg="4">
              
              
            </b-col>
            
            <b-col  cols="4" sm="4" md="4" xl="4" lg="4">
            
            <b-skeleton width="100%"  height="30px"></b-skeleton>
            
            </b-col>
            
            <b-col  cols="4" sm="4" md="4" xl="4" lg="4">
            
            
            </b-col>
            
            <b-col  cols="3" sm="3" md="3" xl="3" lg="3">
            
            
                </b-col>
            
                <b-col  cols="6" sm="6" md="6" xl="6" lg="6">
            
                <b-skeleton width="100%"  height="30px"></b-skeleton>
            
                </b-col>
            
                <b-col  cols="3" sm="3" md="3" xl="3" lg="3">
            
            
                </b-col>
            
            <b-col  cols="12" sm="12" md="12" xl="12" lg="12" style="margin-bottom:10px">
            
            
            
            
                              <b-skeleton-table
                          :rows="2"
                          :columns="columnas"
                          :table-props="{ bordered: true, striped: true }"
                        ></b-skeleton-table>
            
            </b-col>
            
            <b-col  cols="3" sm="3" md="3" xl="3" lg="3">
            
            
            </b-col>
            
            <b-col  cols="6" sm="6" md="6" xl="6" lg="6">
            
            <b-skeleton width="100%"  height="30px"></b-skeleton>
            
            </b-col>
            
            <b-col  cols="3" sm="3" md="3" xl="3" lg="3">
            
            
            </b-col>
            
            <b-col  cols="3" sm="3" md="3" xl="3" lg="3">
            
            
            </b-col>
            
            <b-col  cols="2" sm="2" md="2" xl="2" lg="2">
            <b-skeleton width="100%"  height="30px"></b-skeleton>
            
            
            </b-col>
            
            <b-col  cols="2" sm="2" md="2" xl="2" lg="2" align="center">
            <b-skeleton width="30px" type="avatar"   height="30px"></b-skeleton>
            
            
            </b-col>
            
            <b-col  cols="2" sm="2" md="2" xl="2" lg="2">
            <b-skeleton width="100%"  height="30px"></b-skeleton>
            
            
            </b-col>
            
            
            <b-col  cols="3" sm="3" md="3" xl="3" lg="3">
            
            
            </b-col>
        </b-row>


        <b-row v-else-if="code200">
   
    
    <b-col  cols="12" sm="12" md="12" xl="12" lg="12" style="  overflow: auto;padding: 0px; margin-top:15px">
    
      <table class="table tablesorter"  id="datatableEmpleados" >
                                  <thead class="thead-light">
                                    <tr>
                                      <th>Fecha/hora</th>
                                      <th>Empleado</th>
                                      <th>Monto</th>
                                      <th>Estado</th>

                                    </tr>
                                  </thead>
                                  <tbody>
    
    
                                    <tr v-for="adelanto in adelantos" :key="adelanto.id">
    
                                     <td> {{ adelanto.dateFormated }}</td>
                                     <td> {{ adelanto.displayName }}</td>
                                     <td>${{ adelanto.mount_real }} USD</td>
                                     <td>   <b-badge v-if="adelanto.activo"  variant="success">
                                        Activo
                                      </b-badge>  <b-badge v-else  variant="danger">
                                        Inactivo
                                      </b-badge></td>
                         
                                   
                              
    
                                    </tr>
                                  </tbody>
                                </table>
    
    
     </b-col>
    
    
     
    
     </b-row>
    
     <b-row v-else-if="empty">
    
    
    
     <b-col  align="center" cols="12" sm="12" md="12" xl="12" lg="12" >
      <feather-icon
              icon="UsersIcon"
              size="45"
              class="color_icon"
            />                             
    
                             <p style="margin-top:15px">
                            No se encontraron solicitudes de adelantos de sueldo
                          </p>
    
                  </b-col>
    

    </b-row>

   

    </b-card>
      
      </template>
      
      <script>
      import {
        BButton, BRow, VBPopover, BCol, BCard, BBadge, BSkeleton, BSkeletonTable
      } from 'bootstrap-vue'
      
      import "jquery/dist/jquery.min.js";
    
    import $ from "jquery";
    import 'datatables.net-responsive-dt/css/responsive.dataTables.min.css'
    import 'datatables.net-responsive-dt/js/responsive.dataTables.min.js'
      
      export default {
        components: {
          BSkeleton,
          BBadge,
          BButton,
          BRow,
          BCol,
          BCard,
          BSkeletonTable,
  
        },
        directives: {
          'b-popover': VBPopover,
        },
        props: ['userId', 'tokenAuth', 'idLocal'],
        data() {
      
          const mq = window.matchMedia( "(min-width: 990px)" );
            let columnas=6;
    
            if (!mq.matches) {
    
              columnas =3;
              }
          return {
            code200: false,
          loading: true,
           empty:false,
           columnas:columnas,
           adelantos:[],
          }
        },
        computed: {
      
        },
        watch: {
      
        },
        mounted() {
          this.loadTable();
    
    
        },
        methods: {
  
          refresh() {
          this.$eventBus.$emit('reiniciarSolicitudesAdelantos')
        }, loadTable(){
          
            const data_json = { userId: this.userId, idLocal: this.idLocal }
                  
    
          this.$https.post('/locals/getSolicitudesAdelantosSueldos/', { tokenAuth: this.tokenAuth, data: data_json }).then(response => {
             this.loading=false;

             console.log(JSON.stringify(response.data));

         
                          if(response.data["code"] == 200){
                          
                         
                    
                           
    
                            this.adelantos= response.data["adelantos"];
     
                     
                                      setTimeout(() => {
                                        $("#datatableEmpleados").DataTable({
                                          responsive: true,
                                          "ordering": false,
                                          lengthMenu: [
                                            [5,10, 25, 50, -1],
                                            [5,10, 25, 50, "All"],
                                          ],
                                          pageLength: 5, 
                                             "language": {
                                                  "search": "Buscar:",
                                                  "sInfo":      "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
                                                    "sInfoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
                                                  "searchPlaceholder": "Ingrese algún dato",
                                                  "zeroRecords": "Lo sentimos, no se encontraron resultados",
                                                  "processing": "Buscando. Espere por favor...",
                                                  "sLengthMenu":     "Mostrar _MENU_ registros",
                                                  "oPaginate": {
                                                          "sFirst":    "Primero",
                                                          "sLast":     "Último",
                                                          "sNext":     "Siguiente",
                                                          "sPrevious": "Anterior"
                                                      },
    
                                                  }
                                        });
                                      });
    
                                         this.code200=true;
    
                         
      
                              
                            }else{
    
               
                                
    
    
                                      if (response.data.code == 401) {
    
                                        this.$toast.error(response.data.message, {
                                  position: 'top-right',
                                  timeout: 3010,
                                  closeOnClick: true,
                                  pauseOnFocusLoss: false,
                                  pauseOnHover: true,
                                  draggable: true,
                                  draggablePercent: 0.4,
                                  showCloseButtonOnHover: false,
                                  hideProgressBar: true,
                                  closeButton: 'button',
                                  icon: true,
                                  rtl: false,
                                })
    
    
                                        
                                        localStorage.removeItem('userData')
    
    
    
                                        
                                        
    
               
    
                                        
                                        
    
                                        // Redirect to login page
                                        this.$router.push({ name: 'auth-login' })
                                      } else {
    
                                     

                                            if (response.data.code == 405) {
                                                this.empty=true;
                                            }else{
                                                this.loadTable(); 
                                            }

                                            
    
                                        
                                      
    
    
                                      }
    
                            }
                     }).catch((error) => {
    
                              this.loadTable();
    
                         
                     });
        }
         
         
      
        },
      }
      </script>
      
      <style lang="scss">
      
      
      
      </style>
      